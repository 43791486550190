<template>
  <div id="app">
    <div v-if="loading" class="loading-animation" style="color: #000;">Loading...</div>
    <div v-if="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      error: false,
      company: "",
      errorMessage: "",
    };
  },
  methods: {
  getInstallerUrl: function (companyName) {
    this.loading = true;
    axios
      .get(
        `https://trevor-api.ccup.uk/api/Open/GetInstallerUrl?CompanyName=${encodeURIComponent(
          companyName
        )}&InstallerType=WINDOWS_MSI`
      )
      .then((response) => {
        if (
          response.data &&
          !response.data.includes("No Pax Link For Company Name")
        ) {
          this.redirectToUrl(response.data);
        } else {
          this.error = true;
          this.errorMessage = "No company found.";
        }
      })
      .catch((error) => {
        this.error = true;
        this.errorMessage = `Error: ${error.message}`;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  redirectToUrl: function (url) {
    window.location.href = url;
  },
},
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.company = urlParams.get("c");
    if (this.company) {
      this.getInstallerUrl(this.company);
    }
  },
};
</script>

<style>
.loading-animation {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#app {
  background-image: linear-gradient(to right, #2c3e50, #3498db);
  height: 99vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>